import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../img/logo192.png";

const Menu = () => {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/admin" className="brand-link">
          <img
            src={logo}
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8" }}
            height={50}
          />
          <span className="brand-text font-weight-light">Super Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item has-treeview">
                <Link to="/admin" className="nav-link">
                  <span>
                    <FaHome />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>
              {/*============================ product Setting Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Add-Edit
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Super Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addsupercategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Super Category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/listsupercategory"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Super Category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/showproducts"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Show Massage</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List category</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Sub Category
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addsubcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Sub-category</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listsubcategory" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Sub-category</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Brand
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addbrand">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Brand</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listbrand">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Brand</p>
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                      Products
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addgrocery">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Products</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listgrocery">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Products</p>
                        </Link>
                      </li>
                    </ul>
                    {/* <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin/grocerybulkupload"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Grocery Bulk Upload</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin/grocerypacksizebulkupload"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Grocery PackSize Bulk Upload</p>
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Products
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Electronics
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>

                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/addelectronics"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Electronics</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link
                              to="/admin/listelectronics"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Electronics</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Clothing
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>

                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/addclothing" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Clothing</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/listclothing" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Clothing</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Soft Toys
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>

                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/addsofttoys" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Softtoys</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/listsofttoys" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Softtoys</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Products-Price-Edit
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/product-price-edit"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Products List</p>
                        </Link>
                      </li>
                    </ul>

                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Soft Toys
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>

                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/addsofttoys" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Softtoys</p>
                            </Link>
                          </li>
                        </ul>
                        <ul className="nav nav-treeview productDesign">
                          <li className="nav-item">
                            <Link to="/admin/listsofttoys" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Softtoys</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Subscription
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addsubscription">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Subscription</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listsubscription">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Subscription</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Sliders-Banners
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addsliders">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Sliders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addsubcatbanner">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add SubCatBanner</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addtodayfeatured">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add TodayFeatured</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addbestoffer">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add BestOffer</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addtopbanner">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add TopBanner</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addinfocustoday">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add InFocusToday</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin/addfeaturedinweek"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add FeaturedInWeek</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Stocks
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addstock">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Stock</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Coupons
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addcoupon">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Coupon</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Employees
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addemployee">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Employee</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/bulkuploade">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Bulk Client Uploade </p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/admin/addresbulkuploade"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Bulk Address Uploade </p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Pin-Amount
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addpinamount">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add PinAmount</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <TbBrandReactNative />
                      </span>
                      <p>
                        Offers Banner
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/addoffersbanner">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add OffersBanner</p>
                        </Link>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item has-treeview">
                    <a className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>
                        Tags
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>
                            Category Tags
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview kjhTags">
                          <li className="nav-item">
                            <Link
                              to="/admin/addcategorytag"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Category</p>
                            </Link>
                          </li>
                          <li className="nav-item has-treeview">
                            <Link
                              to="/admin/listcategorytag"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Category</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>
                            Universal Tags
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview kjhTags">
                          <li className="nav-item has-treeview">
                            <Link
                              to="/admin/adduniversaltag"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Univeral Tag</p>
                            </Link>
                          </li>
                          <li className="nav-item has-treeview">
                            <Link
                              to="/admin/listuniversaltag"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Universal Tag</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>
                            Banner Tags
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview kjhTags">
                          <li className="nav-item has-treeview">
                            <Link to="/admin/addbannertag" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Banner Tag</p>
                            </Link>
                          </li>
                          <li className="nav-item has-treeview">
                            <Link
                              to="/admin/listbannertag"
                              className="nav-link"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Banner Tag</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ product Setting end ================================*/}

              {/*============================ Orders Details start ================================*/}
              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Orders Details
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Orders
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/bookedorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Booked Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/processingorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Processing Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/dispatchorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Dispatch Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/outfordeliveryorder"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Out For Delivery</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/deliveredorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Delivered Order</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/cancelledorder" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Cancelled Order</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ Order Details end ================================*/}

              {/*============================ Report & Setting Start ================================*/}
              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Reports & Settings
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>

                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/clientdata" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Client Data</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/processingitems" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Processing Items</p>
                    </Link>
                  </li>
                </ul>
                {/* <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/updatepayment" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Update Payment</p>
                    </Link>
                  </li>
                </ul> */}
                {/* <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/wallet-history" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Wallet History</p>
                    </Link>
                  </li>
                </ul> */}
                {/* <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/wallet-update" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Wallet Update</p>
                    </Link>
                  </li>
                </ul> */}
                {/* <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/substract-wallet" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Substract Wallet Update</p>
                    </Link>
                  </li>
                </ul> */}
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/neworder" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>New Order</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/editorder" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Edit Order</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/changeorderstatus" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Change Order Status</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/productseo" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Product SEO</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/deliveredexport" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Delivered Export</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/exports" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Export</p>
                    </Link>
                  </li>
                </ul>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/websetting" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Web Setting</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/*============================ Report & Setting end ================================*/}
  
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};
export default Menu;
