import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import {
  groceryPost,
  groceryImages,
  validateGrocerySlugUrl,
  result_of_product_Images,
  key_ingredients_Images,
} from "../../../redux/grocery/grocerySlice";

const AddGrocery = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { universaltag } = useSelector((store) => store.Tags);
  const { categorytag } = useSelector((store) => store.Tags);
  const { bannertag } = useSelector((store) => store.Tags);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const {
    mobileimage,
    desktopimage,
    mobileimageicon,
    desktopimageicon,
    isgrocerydeskimageLoading,
    resultproductimage,
    isresultproductimageing,
  } = useSelector((store) => store.groceries);
  

  const dispatch = useDispatch();
  const [productname, setProductname] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [cattag, setCattag] = useState([]);
  const [categorytags, setCategorytags] = useState([]);
  const [unitag, setUnitag] = useState([]);
  const [universaltags, setUniversaltags] = useState([]);

  const [bantag, setBantag] = useState([]);
  const [bannertags, setBannertags] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [brandbysupcat, setBrandbysupcat] = useState([]);
  const [slugUrl, setSlugUrl] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState("");
  const [productoption, setProductoption] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [rating, setRating] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [recommended, setRecommended] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [single, setSingle] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [pcb1, setPcb1] = useState(1);
  const [pcb2, setPcb2] = useState(2);
  const [pcb3, setPcb3] = useState(3);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [gstcost, setGstcost] = useState(0);
  const [prime, setPrime] = useState(Boolean(0));
  const [packsizeoutofstock, setPacksizeoutofstock] = useState(Boolean(0));
  const [packsizeautoupdate, setPacksizeautoupdate] = useState(Boolean(0));
  const [error, setError] = useState(false);
  const [checkUrl, setCheckUrl] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [desktopImages, setDesktopImages] = useState([]);

  const [multidesc, setMultidesc] = useState([
    {
      steps_description: "",
    },
  ]);
  
  const [resultProduct, setResultProduct] = useState([
    {
      product_imgurl: "",
      product_header: "",
      product_description: "",
      editable: true,
    },
  ]);
  const [keyIngredients, setKeyIngredients] = useState([
    {
      product_imgurl: "",
      product_header: "",
      product_description: "",
      editable: true,
    },
  ]);
  const [listIngredients, setListIngredients] = useState([
    {
      ingredients: "",
      type: "",
      where_is_it_from: "",
      how_it_helps: "",
      editable: true,
    },
  ]);

  const [stepsofuse, setStepsofuse] = useState([
    {
      steps_of_use: "",
    },
  ]);
  const [suitablefor, setSuitablefor] = useState([
    {
      steps_of_suitable: "",
    },
  ]);
  const [stepsDisclaimer, setStepsDisclaimer] = useState([
    {
      steps_of_disclaimer: "",
    },
  ]);
  const [imageError, setimageError] = useState("");
  const [packsizeIndex, setpacksizeIndex] = useState("");
  const [currImageIndex, setcurrImageIndex] = useState("");

  const [packsize, setPacksize] = useState([
    {
      packSizeName: "",
      PsPrime: true,
      enabled: false,
      thumbnail: "",
      slider: [],
      PackSizeOptions: [
        {
          packSizeOptionName: "",
          PsnPrime: true,
          Mrp: "",
          Price: "",
          Discount: "",
          enabled: false,
        },
      ],
    },
  ]);

  const [selectedResulIndex, setSelectedResulIndex] = useState("");
  const [selectedKeyIndex, setSelectedKeyIndex] = useState("");
  const [selectedListIngredientsIndex, setSelectedListIngredientsIndex] =
    useState("");

  const [videoHeader, setVideoHeader] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoRecord, setVideoRecord] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [imageLoading, setimageLoading] = useState(true);
  const [keyImageLoading, setKeyImageLoading] = useState(true);

  const [useitBefore, setUseitBefore] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [lisenseNumber, setLisenseNumber] = useState("");
  const [nodalOfficerDetail, setNodalOfficerDetail] = useState("");
  const [dimensions, setDimensions] = useState("");
  const [marketed, setMarketed] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  const selectuniversaltag = (evt) => {
    if (unitag.includes(evt.target.value)) {
      setUnitag(
        unitag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setUnitag((selunitag) => [...selunitag, evt.target.value]);
    }
  };

  const selectbannertag = (evt) => {
    if (bantag.includes(evt.target.value)) {
      setBantag(
        bantag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setBantag((selbantag) => [...selbantag, evt.target.value]);
    }
  };

  const selectcategorytag = (evt) => {
    if (cattag.includes(evt.target.value)) {
      setCattag(
        cattag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setCattag((selcattag) => [...selcattag, evt.target.value]);
    }
  };

  useEffect(() => {
    const cattags = categorytag.filter((tag) => tag.CatId === categoryid);

    setCategorytags(cattags);
  }, [categoryid]);

  const verifyUrl = async (e) => {
    const value = e.target.value;
    setProductname(value);
    setCheckUrl("");
    const groceryUrl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(groceryUrl);

    if (groceryUrl !== "") {
      let response = await dispatch(validateGrocerySlugUrl(groceryUrl));
      if (response.payload.success) {
        setError("Grocery Already exist");
        setErrorcolor("red");
      } else {
        setErrorcolor(" ");
        setError("");
        setCheckUrl(groceryUrl);
      }
    }
  };

  // const discount = Math.round(((mrp - costprice) * 100) / mrp);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    // const brandbysupcat = brandtotal.filter(
    //   (brand) => brand.superCategoryId === supercategoryid
    // );
    const brandbysupcat = [...brandtotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setBrandbysupcat(brandbysupcat);
  }, [supercategoryid, brandtotal]);

  // const imageChange = (index, e) => {
  //   const files = Array.from(e.target.files);
  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = async () => {
  //       if (reader.readyState === 2) {
  //         const getImage = await dispatch(
  //           groceryImages({ desktopImage: reader.result })
  //         );
  //         console.log(getImage, "getImage");
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const imageChange = (index, e) => {
    const files = Array.from(e.target.files);
    const imagePromises = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              groceryImages({ desktopImage: reader.result })
            );

            resolve(reader.result);
          }
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(imagePromises).then((images) => {
      // Update your state to store the multiple images
      setDesktopImages(images);
    });
  };
  
  const [options, setOptions] = useState([
    {
      PackSize: "",
      ImgUrlMbl: mobileimage,
      ImgUrlMblIcon: mobileimageicon,
      ImgUrlDesk: desktopimage,
      ImgUrlDeskIcon: desktopimageicon,
      ImgUrlDeskTiles: "",
      CostPrc: 0,
      GstCost: gstcost,
      SellingPrice: 0,
      GstSellPrc: 0,
      Mrp: 0,
      OutOfStack: packsizeoutofstock,
      Pri: true,
      StockAutoUpdate: packsizeautoupdate,
      Discount: 0,
    },
  ]);


  

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["GstCost"] = gstcost;
    }
    setOptions(temp);
  }, [gstcost]);

  // useEffect(() => {
  //   let temp = [...options];
  //   for (let index = 0; index < options.length; index++) {
  //     temp[index]["Pri"] = prime;
  //   }
  //   setOptions(temp);
  // }, [prime]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["OutOfStack"] = packsizeoutofstock;
    }
    setOptions(temp);
  }, [packsizeoutofstock]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["StockAutoUpdate"] = packsizeautoupdate;
    }
    setOptions(temp);
  }, [packsizeautoupdate]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["ImgUrlMbl"] = mobileimage;
      temp[index]["ImgUrlMblIcon"] = mobileimageicon;
      temp[index]["ImgUrlDesk"] =[...desktopimage]
      temp[index]["ImgUrlDeskIcon"] = desktopimageicon;
    }
    setOptions(temp);
  }, [mobileimage, mobileimageicon, desktopimage, desktopimageicon]);

  

  const handleCheck = (index, e, selected) => {
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "Mrp" || selected === "SellingPrice") &&
      temp[index]["Mrp"] > 0 &&
      temp[index]["SellingPrice"] > 0 &&
      temp[index]["Mrp"] > temp[index]["SellingPrice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const Discount = Math.round(
      ((temp[index]["Mrp"] - temp[index]["SellingPrice"]) * 100) /
        temp[index]["Mrp"]
    );

    temp[index]["Discount"] = Discount;

    setOptions(temp);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        PackSize: "",
        ImgUrlDeskTiles: "",
        ImgUrlMbl: mobileimage,
        ImgUrlMblIcon: mobileimageicon,
        ImgUrlDesk: desktopimage || [],
        ImgUrlDeskIcon: desktopimageicon,
        CostPrc: 0,
        GstCost: gstcost,
        SellingPrice: 0,
        GstSellPrc: 0,
        Mrp: 0,
        OutOfStack: packsizeoutofstock,
        Pri: false,
        StockAutoUpdate: packsizeautoupdate,
        Discount: 0,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };

  const handleDescCheck = (index, e, selected) => {
    let temp = [...multidesc];
    temp[index][selected] = e.target.value;
    setMultidesc(temp);
  };

  const handleMultiDesc = () => {
    setMultidesc([
      ...multidesc,
      {
        steps_description: "",
      },
    ]);
  };

  const removeRowDescClick = (index) => {
    const optionList = [...multidesc];
    optionList.splice(index, 1);
    setMultidesc(optionList);
  };

  const handleHowtoUseCheck = (index, e, selected) => {
    let temp = [...stepsofuse];
    temp[index][selected] = e.target.value;
    setStepsofuse(temp);
  };

  const removeRowUseClick = (index) => {
    const optionList = [...stepsofuse];
    optionList.splice(index, 1);
    setStepsofuse(optionList);
  };

  const handleHowUseDesc = () => {
    setStepsofuse([
      ...stepsofuse,
      {
        steps_of_use: "",
      },
    ]);
  };

  const handleSuitableCheck = (index, e, selected) => {
    let temp = [...suitablefor];
    temp[index][selected] = e.target.value;
    setSuitablefor(temp);
  };

  const removeRowSuitableClick = (index) => {
    const optionList = [...suitablefor];
    optionList.splice(index, 1);
    setSuitablefor(optionList);
  };

  const handleSuitableDesc = () => {
    setSuitablefor([
      ...suitablefor,
      {
        steps_of_suitable: "",
      },
    ]);
  };

  const handleDisclaimerCheck = (index, e, selected) => {
    let temp = [...stepsDisclaimer];
    temp[index][selected] = e.target.value;
    setStepsDisclaimer(temp);
  };

  const removeRowDisclaimerClick = (index) => {
    const optionList = [...stepsDisclaimer];
    optionList.splice(index, 1);
    setStepsDisclaimer(optionList);
  };

  const handleDisclaimerDesc = () => {
    setStepsDisclaimer([
      ...stepsDisclaimer,
      {
        steps_of_disclaimer: "",
      },
    ]);
  };

  const handleFileChange = (event) => {
    setSelectedVideo(event.target.files[0]);
  };

  const uploadVideoClick = async () => {
    setShowLoader(true);
    const apiKey = "918618947122224";
    const cloudName = "dexterous-technology";
    const uploadPreset = "yrevfjck";
    if (selectedVideo) {
      const formData = new FormData();
      formData.append("file", selectedVideo);
      formData.append("upload_preset", "yrevfjck"); // Replace 'your_upload_preset' with your Cloudinary upload preset

      try {
        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/video/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const responseData = await response.json();

          setVideoRecord(responseData.secure_url);
          document.getElementById("videouploading").value = "";
          setShowLoader(false);
        } else {
          // Handle upload failure
          console.error(
            "Failed to upload video to Cloudinary:",
            response.statusText
          );
        }
      } catch (error) {
        // Handle network or server errors
        console.error("Error uploading video to Cloudinary:", error);
      }
    } else {
      console.log("No video selected.");
    }
  };

  const handleResultClick = (index, e, selected) => {
    let temp = [...resultProduct];
    if (selected === "product_imgurl") {
      const files = Array.from(e.target.files);
      setimageLoading(false);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              result_of_product_Images({ resultImage: reader.result })
            );
            if (getImage.payload.success) {
              let image = `${getImage.payload.resultImages}`;
              let getImages = [];
              getImages = [...getImages, image];
              const imageData = [...temp[index][selected], ...getImages];

              temp[index][selected] = imageData;
              temp[index].product_imgurl = imageData[0];
            }
            setimageLoading(true);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      temp[index][selected] = e.target.value;
    }
    setResultProduct(temp);
  };

  const removeImageClick = (imageindex, details) => {
    let resultOption = [...resultProduct];
    resultOption[imageindex].product_imgurl = "";
    setResultProduct(resultOption);
  };

  const handleMultiResult = () => {
    let resul_products = [...resultProduct];
    const find_current_editable = resultProduct.findIndex(
      (data) => data.editable === true
    );
    setSelectedResulIndex("");
    if (
      resultProduct[find_current_editable].product_imgurl !== "" &&
      resultProduct[find_current_editable].product_header !== "" &&
      resultProduct[find_current_editable].product_description !== ""
    ) {
      resul_products[find_current_editable].editable = false;

      setResultProduct([
        ...resul_products,
        {
          product_imgurl: "",
          product_header: "",
          product_description: "",
          editable: true,
        },
      ]);
    } else {
      setSelectedResulIndex(find_current_editable);
    }
  };

  const removeResultRowClick = (e, removeindex) => {
    let resul_products = [...resultProduct];
    resul_products = resultProduct.filter(
      (data, index) => index !== removeindex
    );
    const find_last_index = resul_products.length - 1;
    resul_products[find_last_index].editable = true;
    setResultProduct([...resul_products]);
  };

  const editRowClick = (current_index) => {
    let resul_products = [...resultProduct];
    const find_current_editable = resultProduct.findIndex(
      (data) => data.editable === true
    );
    setSelectedResulIndex("");
    if (
      resultProduct[find_current_editable].product_imgurl !== "" &&
      resultProduct[find_current_editable].product_header !== "" &&
      resultProduct[find_current_editable].product_description !== ""
    ) {
      resul_products[find_current_editable].editable = false;
      resul_products[current_index].editable = true;
      setResultProduct([...resul_products]);
    } else {
      setSelectedResulIndex(find_current_editable);
    }
  };

  const handleKeyIngredientsClick = (index, e, selected) => {
    let temp = [...keyIngredients];
    if (selected === "product_imgurl") {
      const files = Array.from(e.target.files);
      setKeyImageLoading(false);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              key_ingredients_Images({ keyingredientsImage: reader.result })
            );
            if (getImage.payload.success) {
              let image = `${getImage.payload.KeyIngredientsImages}`;
              let getImages = [];
              getImages = [...getImages, image];
              const imageData = [...temp[index][selected], ...getImages];

              temp[index][selected] = imageData;
              temp[index].product_imgurl = imageData[0];
            }
            setKeyImageLoading(true);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      temp[index][selected] = e.target.value;
    }
    setKeyIngredients(temp);
  };

  const removeKeyImageClick = (imageindex, details) => {
    let resultOption = [...keyIngredients];
    resultOption[imageindex].product_imgurl = "";
    setKeyIngredients(resultOption);
  };

  const removeKeyRowClick = (e, removeindex) => {
    let resul_products = [...keyIngredients];
    resul_products = keyIngredients.filter(
      (data, index) => index !== removeindex
    );
    const find_last_index = resul_products.length - 1;
    resul_products[find_last_index].editable = true;
    setKeyIngredients([...resul_products]);
  };

  const editKeyRowClick = (current_index) => {
    let resul_products = [...keyIngredients];
    const find_current_editable = keyIngredients.findIndex(
      (data) => data.editable === true
    );
    setSelectedKeyIndex("");
    if (
      keyIngredients[find_current_editable].product_imgurl !== "" &&
      keyIngredients[find_current_editable].product_header !== "" &&
      keyIngredients[find_current_editable].product_description !== ""
    ) {
      resul_products[find_current_editable].editable = false;
      resul_products[current_index].editable = true;
      setKeyIngredients([...resul_products]);
    } else {
      setSelectedKeyIndex(find_current_editable);
    }
  };

  const handleMultiKeyIngredients = () => {
    let resul_products = [...keyIngredients];
    const find_current_editable = keyIngredients.findIndex(
      (data) => data.editable === true
    );
    setSelectedKeyIndex("");
    if (
      keyIngredients[find_current_editable].product_imgurl !== "" &&
      keyIngredients[find_current_editable].product_header !== "" &&
      keyIngredients[find_current_editable].product_description !== ""
    ) {
      resul_products[find_current_editable].editable = false;

      setKeyIngredients([
        ...resul_products,
        {
          product_imgurl: "",
          product_header: "",
          product_description: "",
          editable: true,
        },
      ]);
    } else {
      setSelectedKeyIndex(find_current_editable);
    }
  };

  const handleListofIngredientsClick = (index, e, selected) => {
    let temp = [...listIngredients];
    temp[index][selected] = e.target.value;
    setListIngredients(temp);
  };

  const removeListIngredientsClick = (e, removeindex) => {
    let resul_products = [...listIngredients];
    resul_products = listIngredients.filter(
      (data, index) => index !== removeindex
    );
    const find_last_index = resul_products.length - 1;
    resul_products[find_last_index].editable = true;
    setListIngredients([...resul_products]);
  };

  const editListIngredientsClick = (current_index) => {
    let resul_products = [...listIngredients];
    const find_current_editable = listIngredients.findIndex(
      (data) => data.editable === true
    );
    setSelectedListIngredientsIndex("");
    if (
      listIngredients[find_current_editable].ingredients !== "" &&
      listIngredients[find_current_editable].type !== "" &&
      listIngredients[find_current_editable].where_is_it_from !== "" &&
      listIngredients[find_current_editable].how_it_helps !== ""
    ) {
      resul_products[find_current_editable].editable = false;
      resul_products[current_index].editable = true;
      setListIngredients([...resul_products]);
    } else {
      setSelectedListIngredientsIndex(find_current_editable);
    }
  };

  const handleMultiListIngredientsIngredients = () => {
    let resul_products = [...listIngredients];
    const find_current_editable = listIngredients.findIndex(
      (data) => data.editable === true
    );
    setSelectedKeyIndex("");
    if (
      listIngredients[find_current_editable].ingredients !== "" &&
      listIngredients[find_current_editable].type !== "" &&
      listIngredients[find_current_editable].where_is_it_from !== "" &&
      listIngredients[find_current_editable].how_it_helps !== ""
    ) {
      resul_products[find_current_editable].editable = false;

      setListIngredients([
        ...resul_products,
        {
          ingredients: "",
          type: "",
          where_is_it_from: "",
          how_it_helps: "",
          editable: true,
        },
      ]);
    } else {
      setSelectedListIngredientsIndex(find_current_editable);
    }
  };
  
  const handlePackCheck = (
    index,
    e,
    selected,
    psoptionStatus,
    psIndex,
    psName
  ) => {
    let temp = [...packsize];
    if (selected === "slider") {
      const files = Array.from(e.target.files);
      setimageLoading(false);
      setcurrImageIndex(index);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              groceryImages({ groceryImages: reader.result })
            );
            if (getImage.payload.success) {
              let image = `${getImage.payload.groceryImages}`;
              let getImages = [];
              getImages = [...getImages, image];
              const imageData = [...temp[index][selected], ...getImages];
              temp[index][selected] = imageData;
              temp[index].thumbnail = imageData[0];
            }
            setimageLoading(true);
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "thumbnail") {
      temp[index][selected] = e;
    } else if (selected === "PsPrime") {
      if (temp.length > 1) {
        temp = temp.map((data) => {
          data.PsPrime = false;
          return data;
        });
        temp[index][selected] = true;
      }
    } else if (psoptionStatus === true) {
      temp = temp.map((data, cuurentIndex) => {
        if (cuurentIndex === index) {
          if (psName === "packSizeOptionName") {
            data.PackSizeOptions[psIndex][psName] = e.target.value;
          } else if (psName === "PsnPrime") {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                optiondata.PsnPrime = false;
                return optiondata;
              }
            );
            data.PackSizeOptions[psIndex][psName] = true;
          } else {
            if (e.target.value.startsWith(0)) {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim()
                  .slice(1);
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                }
              }
              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                  Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            } else {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim();
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                }
              }

              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                  Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            }
          }
        }
        return data;
      });
      temp = temp;
    } else if (
      selected !== "slider" &&
      selected !== "thumbnail" &&
      selected !== "PsPrime" &&
      psoptionStatus === false
    ) {
      temp[index][selected] = e.target.value;
    }
    setPacksize(temp);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkUrl !== "") {
      const newOptions = options.map(indOpt => ({
        ...indOpt,  // Spread the existing properties
        ImgUrlDesk: indOpt.ImgUrlDesk.map(indImg => ({
          ImgUrlDeskShow: indImg
        }))
      }));
      const formData = {
        ItemName:
          productname.charAt(0).toUpperCase() +
          productname.slice(1).toLowerCase(),
        Url: slugUrl,
        superCategory: supercategory,
        superCategoryId: supercategoryid,
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        Brand: brand,
        ProductInfo: productinfo,
        About: about,
        Ingredient: ingredient,
        Rating: rating,
        KeyWords: metakeyword,
        Description: metadesc,
        Title: metatitle,
        Type: type,
        Options: productoption,
        Recommends: recommended,
        Multi: multi,
        Single: single,
        Cashback: cashback,
        HotProducts: hotproducts,
        Trending: trending,
        Offers: offers,
        Pcb1: 1,
        Pcb2: 2,
        Pcb3: 3,
        UniversalTag: unitag.join(", "),
        BannerTag: bantag.join(", "),
        CategoryTag: cattag.join(", "),
        PackSizes: newOptions,
        multi_description: multidesc,
        video_header: videoHeader,
        video: videoRecord,
        result_of_product: resultProduct,
        key_ingredients: keyIngredients,
        how_to_use: stepsofuse,
        suitable_for: suitablefor,
        list_of_ingredients: listIngredients,
        disclaimer_of_product: stepsDisclaimer,
        use_before: useitBefore,
        manufacturer: manufacturer,
        lisense_number: lisenseNumber,
        nodal_officer_detail: nodalOfficerDetail,
        dimensions: dimensions,
        marketed_by: marketed,
        country_of_origin: countryOrigin,
      };

      console.log(formData,"formData");
      const data = await dispatch(groceryPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Grocery Added");
          setProductname("");
          setSupercategory("");
          setCategory("");
          setBrand("");
          setSubcategory("");
          setSlugUrl("");
          setAbout("");
          setType("");
          setProductoption("");
          setIngredient("");
          setRating("");
          setProductinfo("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setMultidesc([]);
          setResultProduct([]);
          setKeyIngredients([]);
          setListIngredients([]);
          setStepsofuse([]);
          setSuitablefor([]);
          setStepsDisclaimer([]);
          setVideoHeader("");
          setSelectedVideo("");
          setVideoRecord("");
          setUseitBefore("");
          setManufacturer("");
          setLisenseNumber("");
          setNodalOfficerDetail("");
          setDimensions("");
          setMarketed("");
          setCountryOrigin("");
        } else {
          alert(data.message);
          console.log("hi");
        }
      }
    } else {
      alert("please enter correct values");
    }
  };
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsupCategory(e);
                        }}
                        value={superCatTotal._id}
                        name={superCatTotal.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {superCatTotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbysuper._id}
                        name={catbysuper.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbysuper.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={brandbysupcat.name}
                        name={brandbysupcat.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {brandbysupcat.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name....."
                        required
                        value={productname}
                        onChange={(e) => verifyUrl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        required
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Rating
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <div>
                    {multidesc.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Description
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Enter Description"
                              style={{ height: "100px" }}
                              required
                              value={details?.steps_description}
                              onChange={(e) =>
                                handleDescCheck(index, e, "steps_description")
                              }
                            />
                          </Col>
                          {multidesc.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowDescClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleMultiDesc}
                    >
                      Add More
                    </Button>
                  </div>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="gstcost">Video Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Video Name"
                        required
                        value={videoHeader}
                        onChange={(e) => setVideoHeader(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Product Video</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        accept="video/*"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn btn-primary m-3"
                          onClick={() => uploadVideoClick()}
                        >
                          Add Video
                        </button>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {!showLoader ? (
                          <div></div>
                        ) : (
                          <iframe
                            title="Video Player"
                            width="50%" // Set width to 100% to fill the container
                            height="250px" // Set height to auto to maintain aspect ratio
                            src={videoRecord}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div>
                    {resultProduct.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label>Result of Product Image</Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="No File Choosen"
                              // onChange={resultImageChange}
                              onChange={(e) =>
                                handleResultClick(index, e, "product_imgurl")
                              }
                              className={
                                index === selectedResulIndex &&
                                details?.product_imgurl === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                            <p>
                              * The image width and height should be 450px *
                              450px
                            </p>
                            <div>
                              {!imageLoading ? (
                                <div>
                                  <h6>Image Loading...</h6>
                                </div>
                              ) : (
                                <div>
                                  {details?.product_imgurl !== "" && (
                                    <div className="imageBox">
                                      <img
                                        src={details?.product_imgurl}
                                        height={150}
                                        alt="image"
                                        style={{
                                          border: "2px solid green",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      />

                                      <div
                                        className="imgremBtn"
                                        onClick={() =>
                                          removeImageClick(index, details)
                                        }
                                        disabled={!details?.editable}
                                      >
                                        X
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Result of Product Header
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Header Name"
                              value={details?.product_header}
                              onChange={(e) =>
                                handleResultClick(index, e, "product_header")
                              }
                              className={
                                index === selectedResulIndex &&
                                details?.product_header === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Result of Product About
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter About"
                              value={details?.product_description}
                              onChange={(e) =>
                                handleResultClick(
                                  index,
                                  e,
                                  "product_description"
                                )
                              }
                              className={
                                index === selectedResulIndex &&
                                details?.product_description === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          {resultProduct.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeResultRowClick(e, index)}
                                disabled={!details?.editable}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                              {details?.editable === false && (
                                <Button
                                  className="btn btn-primary mx-lg-3"
                                  style={{ marginTop: "32px" }}
                                  onClick={() => editRowClick(index)}
                                >
                                  <div>EDIT</div>
                                </Button>
                              )}
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={() => handleMultiResult()}
                    >
                      Add More
                    </Button>
                  </div>

                  <div>
                    {keyIngredients.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label>Key Ingredients Image</Form.Label>
                            <Form.Control
                              type="file"
                              placeholder="No File Choosen"
                              onChange={(e) =>
                                handleKeyIngredientsClick(
                                  index,
                                  e,
                                  "product_imgurl"
                                )
                              }
                              className={
                                index === selectedKeyIndex &&
                                details?.product_imgurl === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                            <p>
                              * The image width and height should be 450px *
                              450px
                            </p>
                            <div>
                              {!keyImageLoading ? (
                                <div>
                                  <h6>Image Loading...</h6>
                                </div>
                              ) : (
                                <div>
                                  {details?.product_imgurl !== "" && (
                                    <div className="imageBox">
                                      <img
                                        src={details?.product_imgurl}
                                        height={150}
                                        alt="image"
                                        style={{
                                          border: "2px solid green",
                                          cursor: "pointer",
                                          width: "100%",
                                        }}
                                      />

                                      <div
                                        className="imgremBtn"
                                        onClick={() =>
                                          removeKeyImageClick(index, details)
                                        }
                                        disabled={!details?.editable}
                                      >
                                        X
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Key Ingredients Header
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Header Name"
                              value={details?.product_header}
                              onChange={(e) =>
                                handleKeyIngredientsClick(
                                  index,
                                  e,
                                  "product_header"
                                )
                              }
                              className={
                                index === selectedKeyIndex &&
                                details?.product_header === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Key Ingredients About
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter About"
                              value={details?.product_description}
                              onChange={(e) =>
                                handleKeyIngredientsClick(
                                  index,
                                  e,
                                  "product_description"
                                )
                              }
                              className={
                                index === selectedKeyIndex &&
                                details?.product_description === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          {keyIngredients.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeKeyRowClick(e, index)}
                                disabled={!details?.editable}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                              {details?.editable === false && (
                                <Button
                                  className="btn btn-primary mx-lg-3"
                                  style={{ marginTop: "32px" }}
                                  onClick={() => editKeyRowClick(index)}
                                >
                                  <div>EDIT</div>
                                </Button>
                              )}
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={() => handleMultiKeyIngredients()}
                    >
                      Add More
                    </Button>
                  </div>

                  <div>
                    {stepsofuse.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              How to Use
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="How to use a Product"
                              style={{ height: "50px" }}
                              required
                              value={details?.steps_of_use}
                              onChange={(e) =>
                                handleHowtoUseCheck(index, e, "steps_of_use")
                              }
                            />
                          </Col>
                          {stepsofuse.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowUseClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleHowUseDesc}
                    >
                      Add More
                    </Button>
                  </div>

                  <div>
                    {suitablefor.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Suitable For
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Suitable For..."
                              style={{ height: "50px" }}
                              required
                              value={details?.steps_of_suitable}
                              onChange={(e) =>
                                handleSuitableCheck(
                                  index,
                                  e,
                                  "steps_of_suitable"
                                )
                              }
                            />
                          </Col>
                          {suitablefor.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) =>
                                  removeRowSuitableClick(e, index)
                                }
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleSuitableDesc}
                    >
                      Add More
                    </Button>
                  </div>

                  <div>
                    {listIngredients.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Ingredients
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Ingredients Name"
                              value={details?.ingredients}
                              onChange={(e) =>
                                handleListofIngredientsClick(
                                  index,
                                  e,
                                  "ingredients"
                                )
                              }
                              className={
                                index === selectedListIngredientsIndex &&
                                details?.ingredients === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Ingredients Type
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter About"
                              value={details?.type}
                              onChange={(e) =>
                                handleListofIngredientsClick(index, e, "type")
                              }
                              className={
                                index === selectedListIngredientsIndex &&
                                details?.type === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              Where Is It From?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Where Is It From"
                              value={details?.where_is_it_from}
                              onChange={(e) =>
                                handleListofIngredientsClick(
                                  index,
                                  e,
                                  "where_is_it_from"
                                )
                              }
                              className={
                                index === selectedListIngredientsIndex &&
                                details?.where_is_it_from === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="gstcost">
                              How It Helps?
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="How It Helps"
                              value={details?.how_it_helps}
                              onChange={(e) =>
                                handleListofIngredientsClick(
                                  index,
                                  e,
                                  "how_it_helps"
                                )
                              }
                              className={
                                index === selectedListIngredientsIndex &&
                                details?.how_it_helps === ""
                                  ? "error_class"
                                  : ""
                              }
                              disabled={!details?.editable}
                            />
                          </Col>

                          {listIngredients.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) =>
                                  removeListIngredientsClick(e, index)
                                }
                                disabled={!details?.editable}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                              {details?.editable === false && (
                                <Button
                                  className="btn btn-primary mx-lg-3"
                                  style={{ marginTop: "32px" }}
                                  onClick={() =>
                                    editListIngredientsClick(index)
                                  }
                                >
                                  <div>EDIT</div>
                                </Button>
                              )}
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={() => handleMultiListIngredientsIngredients()}
                    >
                      Add More
                    </Button>
                  </div>

                  <div>
                    {stepsDisclaimer.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Disclaimer
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Write Disclaimer"
                              style={{ height: "50px" }}
                              required
                              value={details?.steps_of_disclaimer}
                              onChange={(e) =>
                                handleDisclaimerCheck(
                                  index,
                                  e,
                                  "steps_of_disclaimer"
                                )
                              }
                            />
                          </Col>
                          {stepsDisclaimer.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) =>
                                  removeRowDisclaimerClick(e, index)
                                }
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleDisclaimerDesc}
                    >
                      Add More
                    </Button>
                  </div>

                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Use It Before
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Months"
                        style={{ height: "50px" }}
                        value={useitBefore}
                        onChange={(e) => setUseitBefore(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Manufacturer
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Manufacturer"
                        style={{ height: "50px" }}
                        value={manufacturer}
                        onChange={(e) => setManufacturer(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Lisense Number
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Lisense Number"
                        style={{ height: "50px" }}
                        value={lisenseNumber}
                        onChange={(e) => setLisenseNumber(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Nodal Officer Detail
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Nodal Officer Detail"
                        style={{ height: "50px" }}
                        value={nodalOfficerDetail}
                        onChange={(e) => setNodalOfficerDetail(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Dimensions
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Dimensions"
                        style={{ height: "50px" }}
                        value={dimensions}
                        onChange={(e) => setDimensions(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Marketed By
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Write Marketed By"
                        style={{ height: "50px" }}
                        value={marketed}
                        onChange={(e) => setMarketed(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Country Of Origin
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Country"
                        style={{ height: "50px" }}
                        value={countryOrigin}
                        onChange={(e) => setCountryOrigin(e.target.value)}
                      />
                    </Col>
                  </Row>

                  {/* <Row className="g-2 m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info....."
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">About</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Ingradient*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Ingradient....."
                        value={ingredient}
                        onChange={(e) => setIngredient(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label> Keywords </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label> Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info....."
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Options
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={productoption}
                        onChange={(e) => setProductoption(e.target.value)}
                      />
                    </Col>
                  </Row> */}

                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="recommended"
                        checked={recommended === true}
                        onChange={(e) => {
                          setRecommended(e.target.checked);
                        }}
                      />
                      <Form.Label>Recommends</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="radio"
                        name="radiobtn"
                        value="multi"
                        className="me-1"
                        checked={multi === true}
                        onChange={(e) => {
                          setMulti(e.target.checked);
                        }}
                      />
                      <Form.Label>Multi </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="radio"
                        name="radiobtn"
                        value="single"
                        className="me-1"
                        checked={single === true}
                        onChange={(e) => {
                          setSingle(e.target.checked);
                        }}
                      />
                      <Form.Label>Single </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="cashback"
                        checked={cashback === true}
                        onChange={(e) => {
                          setCashback(e.target.checked);
                        }}
                      />
                      <Form.Label>Cashback</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="hotproducts"
                        checked={hotproducts === true}
                        onChange={(e) => {
                          setHotproducts(e.target.checked);
                        }}
                      />
                      <Form.Label>Hot Products</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="trending"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending</Form.Label>
                    </Col>

                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offer</Form.Label>
                    </Col>
                  </Row>

                  <hr color="black" />
                  <div className="categorytag">
                    <div>
                      <h5>Universal Tags</h5>
                      {universaltag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={unitag.includes(tag.name)}
                              onChange={(e) => {
                                selectuniversaltag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <h5>Category Tags</h5>
                      {categorytags.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={cattag.includes(tag.name)}
                              onChange={(e) => {
                                selectcategorytag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <h5>Banner Tags</h5>
                      {bannertag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={bantag.includes(tag.name)}
                              onChange={(e) => {
                                selectbannertag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {packsize.map((details, index) => (
                    <Row className="g-2  m-2">
                      <Col md>
                        <Form.Label>Grocery Image</Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="No File Choosen"
                          onChange={(e) => imageChange(index, e)}
                          multiple
                          required
                        />
                        <p>
                          * The image width and height should be 450px * 450px
                        </p>
                        <div>
                          {isgrocerydeskimageLoading ? (
                            <div></div>
                          ) : (
                            desktopImages.map((image, idx) => (
                              <img
                                key={idx}
                                src={image}
                                height={100}
                                alt={`img-${idx}`}
                              />
                            ))
                            // <img src={desktopimage} height={100} alt="img" />
                          )}
                        </div>
                      </Col>
                      {/* <Col md>
                        <Form.Label>
                          Grocery Image
                          <span style={{ color: "red" }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          placeholder="No File Choosen"
                          disabled={details?.enabled === true}
                          className={
                            imageError !== "" && packsizeIndex === index
                              ? "error_class"
                              : ""
                          }
                          onChange={(e) =>
                            handlePackCheck(index, e, "slider", false, "", "")
                          }
                          multiple
                        />
                        <p>
                          * The image width and height should be 500px * 500px
                        </p>
                        <div>
                          {!imageLoading && currImageIndex === index ? (
                            <div>
                              <h6>Image Loading...</h6>
                            </div>
                          ) : (
                            <>
                              <div className="imagecontainer">
                                {details?.slider.map((image, imgindex) => (
                                  <>
                                    <div className="imageBox">
                                      <img
                                        src={image}
                                        height={100}
                                        alt="img"
                                        key={imgindex}
                                        className="mx-1 my-1"
                                        onClick={(e) =>
                                          handlePackCheck(
                                            index,
                                            image,
                                            "thumbnail",
                                            false,
                                            "",
                                            ""
                                          )
                                        }
                                        style={
                                          image === details?.thumbnail
                                            ? {
                                                border: "2px solid green",
                                                cursor: "pointer",
                                                // position: "relative",
                                              }
                                            : {
                                                cursor: "pointer",
                                                // position: "relative",
                                              }
                                        }
                                      />
                                      {details?.enabled === true ? (
                                        <></>
                                      ) : (
                                        <>
                                          <div
                                            className="imgremBtn"
                                            onClick={() =>
                                              removeImageClick(
                                                index,
                                                imgindex,
                                                image
                                              )
                                            }
                                          >
                                            X
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </Col> */}
                      <Col md>
                        <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                        <Form.Control
                          type="number"
                          value={gstcost}
                          required
                          onChange={(e) => setGstcost(e.target.value)}
                        />
                      </Col>
                    </Row>
                  ))}

                  {/* <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="prime"
                        checked={prime === true}
                        onChange={(e) => {
                          setPrime(e.target.checked);
                        }}
                      />
                      <Form.Label>Prime</Form.Label>
                    </Col>
                  </Row> */}

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Pack Sizes</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Pack Size
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.PackSize}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "PackSize")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              ImgUrlDeskTiles
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.ImgUrlDeskTiles}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "ImgUrlDeskTiles")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Cost Prcice
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.CostPrc}
                              required
                              onChange={(e) => handleCheck(index, e, "CostPrc")}
                            />
                          </Col>
                          {/* <Col md>
                            <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.GstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "GstCost")}
                            />
                          </Col> */}
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.SellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "SellingPrice")
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              minLength={2}
                              maxLength={2}
                              value={details?.GstSellPrc}
                              onChange={(e) =>
                                handleCheck(index, e, "GstSellPrc")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.Mrp}
                              onChange={(e) => handleCheck(index, e, "Mrp")}
                            />
                          </Col>
                          <Col style={{ marginTop: "45px" }}>
                            <input
                              type="checkbox"
                              className="me-1"
                              value="packsizeoutofstock"
                              checked={packsizeoutofstock === true}
                              onChange={(e) => {
                                setPacksizeoutofstock(e.target.checked);
                              }}
                            />
                            <Form.Label>OutOfStack</Form.Label>
                          </Col>
                          <Col style={{ marginTop: "45px" }}>
                            <input
                              type="checkbox"
                              className="me-1"
                              value="packsizeautoupdate"
                              checked={packsizeautoupdate === true}
                              onChange={(e) => {
                                setPacksizeautoupdate(e.target.checked);
                              }}
                            />
                            <Form.Label>StockAutoUpdate</Form.Label>
                          </Col>

                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleNewRow}
                    >
                      Add More
                    </Button>
                  </div>
                </div>

                <div className="card-footer text-center">
                  {checkUrl !== "" &&
                  subcategoryid !== "" &&
                  categoryid !== "" &&
                  !isgrocerydeskimageLoading ? (
                    <button className="btn btn-danger" type="submit">
                      Add Product
                    </button>
                  ) : (
                    <button className="btn btn-danger" disabled type="submit">
                      Add Product
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddGrocery;
