import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  // grocerytotal: [],
  grocerytotal: [],
  mobileimage: "",
  mobileimageicon: "",
  desktopimage: [],
  desktopimageicon: "",
  isgrocerymobileimageLoading: true,
  isgrocerymobileimageiconLoading: true,
  isgrocerydeskimageLoading: true,
  isgrocerydeskimageiconLoading: true,
  isLoading: true,
  groceryLoading: true,
  isgroceryLoading: true,
  delgroceyLoading: true,
};

export const getGrocery = createAsyncThunk(
  "grocery/getGrocery",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/grocery/all`;
      const resp = await axios(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const groceryPost = createAsyncThunk(
  "grocery/groceryPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const postClientRegistation = createAsyncThunk(
  "grocery/postClientRegistation",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/client/new`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("client Not create");
    }
  }
);

export const groceryUpdate = createAsyncThunk(
  "grocery/groceryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/${formData.grocedeyid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const groceryDelete = createAsyncThunk(
  "grocery/groceryDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const groceryImages = createAsyncThunk(
  "grocery/groceryImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/groceryimages`;
      const resp = await axios.post(url, formData, config);
      console.log(resp.data, "resp.data");

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const result_of_product_Images = createAsyncThunk(
  "grocery/result_of_product_Images",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/resultimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const key_ingredients_Images = createAsyncThunk(
  "grocery/key_ingredients_Images",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/grocery/keyingredientsimage`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

export const validateGrocerySlugUrl = createAsyncThunk(
  "grocery/validateGrocerySlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    };
    try {
      const url = `${Baseurl}/api/v1/grocery/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const UpdateGroceryPackSize = createAsyncThunk(
  "grocery/UpdateGroceryPackSize",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/packsize/${formData.ProductId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);
export const UpdateGrocerySinglePackSize = createAsyncThunk(
  "grocery/UpdateGrocerySinglePackSize",
  async (formData, thunkAPI) => {
    try {
      const config = {
        maxBodyLength: Infinity,
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/grocery/singlepacksizeprice`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("grocery Not create");
    }
  }
);

const GrocerySlice = createSlice({
  name: "groceries",
  initialState,
  reducers: {
    updateGroceryMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.isgrocerymobileimageLoading = false;
    },
    updateGroceryMobileImageIcon(state, action) {
      state.mobileimageicon = action.payload;
      state.isgrocerymobileimageiconLoading = false;
    },
    updateGroceryDeskimage(state, action) {
      state.desktopimage = [...action.payload];
      state.isgrocerydeskimageLoading = false;
    },
    updateGroceryDeskIconimage(state, action) {
      state.desktopimageicon = action.payload;
      state.isgrocerydeskimageiconLoading = false;
    },
    deleteDesktopImage(state,action){
      state.desktopimage = state.desktopimage.filter((item, index) => index !== action.payload);
      console.log(state.desktopimage.length,'length');
      
    }
  },
  extraReducers: {
    [getGrocery.pending]: (state) => {
      state.isLoading = true;
    },
    [getGrocery.fulfilled]: (state, action) => {
      state.grocerytotal = action.payload;
      state.isLoading = false;
      state.groceryLoading = false;
      state.isgroceryLoading = false;
    },
    [getGrocery.rejected]: (state, action) => {
      state.isLoading = true;
    },

    [groceryPost.pending]: (state) => {
      state.groceryLoading = true;
    },

    [groceryPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        state.mobileimage = "";
        state.mobileimageicon = "";
        state.desktopimage = [];
        state.desktopimageicon = "";
        state.isgrocerymobileimageLoading = true;
        state.isgrocerymobileimageiconLoading = true;
        state.isgrocerydeskimageLoading = true;
        state.isgrocerydeskimageiconLoading = true;
      }
      state.groceryLoading = false;
    },

    [groceryPost.rejected]: (state, action) => {
      state.groceryLoading = true;
    },

    [groceryUpdate.pending]: (state) => {
      state.groceryLoading = true;
    },

    [groceryUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.grocery._id
        );
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        // state.mobileimage = "";
        // state.mobileimageicon = "";
        // state.desktopimage = "";
        // state.desktopimageicon = "";
        // state.isgrocerymobileimageLoading = true;
        // state.isgrocerymobileimageiconLoading = true;
        // state.isgrocerydeskimageLoading = true;
        // state.isgrocerydeskimageiconLoading = true;
        state.isLoading = !state.isLoading;
      }
      state.groceryLoading = false;
    },

    [groceryUpdate.rejected]: (state, action) => {
      state.groceryLoading = true;
    },

    [groceryDelete.pending]: (state) => {
      state.delgroceyLoading = true;
    },

    [groceryDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.id
        );
      }
      state.delgroceyLoading = false;
    },

    [groceryDelete.rejected]: (state, action) => {
      state.delgroceyLoading = true;
    },

    [groceryImages.pending]: (state) => {
      state.isgrocerydeskimageLoading = true;
    },
    [groceryImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileimages;
        state.mobileimageicon = action.payload.mobileimageIcons;
        console.log(state.desktopimage, "state.desktopimage");

        state.desktopimage = [
          ...state.desktopimage,
          action.payload.desktopImages,
        ];
        console.log(state.desktopimage, "state.desktopimage");

        state.desktopimageicon = action.payload.desktopImageIcons;
      }

      state.isgrocerydeskimageLoading = false;
      state.isgrocerydeskimageiconLoading = false;
      state.isgrocerymobileimageiconLoading = false;
      state.isgrocerymobileimageLoading = false;
    },
    [groceryImages.rejected]: (state) => {
      state.isgrocerydeskimageLoading = true;
    },

    [UpdateGroceryPackSize.pending]: (state) => {
      state.groceryLoading = true;
    },

    [UpdateGroceryPackSize.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.grocery._id
        );
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
        state.mobileimage = "";
        state.mobileimageicon = "";
        state.desktopimage = [];
        state.desktopimageicon = "";
        state.isgrocerymobileimageLoading = true;
        state.isgrocerymobileimageiconLoading = true;
        state.isgrocerydeskimageLoading = true;
        state.isgrocerydeskimageiconLoading = true;
        state.isLoading = !state.isLoading;
      }
      state.groceryLoading = false;
    },
    [UpdateGrocerySinglePackSize.pending]: (state) => {
      state.groceryLoading = true;
    },

    [UpdateGrocerySinglePackSize.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.grocerytotal = state.grocerytotal.filter(
          (grocery) => grocery._id !== action.payload.grocery._id
        );
        state.grocerytotal = [...state.grocerytotal, action.payload.grocery];
      }
      state.groceryLoading = false;
    },

    [UpdateGrocerySinglePackSize.rejected]: (state, action) => {
      state.groceryLoading = true;
    },
  },
});
export const {
  deleteDesktopImage,
  updateGroceryMobileImage,
  updateGroceryDeskimage,
  updateGroceryMobileImageIcon,
  updateGroceryDeskIconimage,
} = GrocerySlice.actions;
export default GrocerySlice.reducer;
